import React, { useState, useEffect } from 'react';

const App = () => {
  const [text, setText] = useState('');
  const fullText = 'lw&co';

  useEffect(() => {
    if (text.length < fullText.length) {
      const timeoutId = setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [text]);

  return (
    <>
      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');

        body {
          margin: 0;
          padding: 0;
          overflow-x: hidden;
        }

        @keyframes blink {
          from, to { border-color: transparent }
          50% { border-color: #37352f; }
        }

        @media (max-width: 480px) {
          h1 {
            font-size: 2rem !important;
          }
        }
      `}</style>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#f7f6f3',
        fontFamily: "'Noto Sans Mono', monospace",
        padding: '20px',
        boxSizing: 'border-box'
      }}>
        <h1 style={{
          fontSize: 'clamp(2rem, 8vw, 6rem)',
          color: '#37352f',
          textAlign: 'center',
          fontWeight: '600',
          letterSpacing: '0.05em',
          wordBreak: 'break-word',
          maxWidth: '100%'
        }}>
          {text}
          <span style={{ borderRight: '0.15em solid #37352f', animation: 'blink 0.7s step-end infinite' }}></span>
        </h1>
      </div>
    </>
  );
};

export default App;